import client, {ConfigWithToken, get} from './client'

const verifyToken = async () => {
    try {
        const {data: response} = await client.get(`users/me`, ConfigWithToken()).then(response => response.data);
        return response;
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }


}
const logout = async () => {
    let token = localStorage.getItem('token');

    if (!token) {
        return false;
    }
    try {
        return await get(`logout/${token}`)
    } catch (error) {
        console.log(error);
    }
}
export const LOGIN_URL = process.env.REACT_APP_VERACITY_BASE_URL + '/login';

export default {verifyToken, logout};


