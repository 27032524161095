
export default function PageLoading(){

    const wrapperStyles={
        width: '100vw',
        height: '100vh',
        backgroundColor: '#303956'
    }

    return (
        <div style={wrapperStyles} className='loading-wrapper'>
            <div className='loading'>

            </div>
        </div>
    )
}