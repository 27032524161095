import client, {ConfigWithToken} from './client'
import {ProjectType, ProjectUserRolesType} from '../components/types';

export const getProject = async (id: any): Promise<ProjectType> => {
    try {
        const {data: response} = await client.get(`project/${id}`, ConfigWithToken()).then(response => response.data)        
        return response;
    } catch (error) {
        return Promise.reject(error);
    }
}
export const getProjectUserRoles = async (id: any): Promise<ProjectUserRolesType> => {
    try {
        const {data: response} = await client.get(`project/${id}/user-roles`, ConfigWithToken())
        return response;
        // return roles;
    } catch (error) {
        return Promise.reject(error);
    }
}
export const getProjects = async (): Promise<ProjectType[]> => {
    try {
        const {data: response} = await client.get(`project/15`, ConfigWithToken()).then(response => response.data);
        return response;
    } catch (error) {
        return Promise.reject(error);
    }
}

