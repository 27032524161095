import { Route } from "react-router-dom";
import { ProjectProvider } from "./contexts/ProjectContext";
import React, { lazy } from "react";

const ProjectPage = lazy(() => import('./components/common/ProjectPage'));
const Projects = lazy(() => import('./components/common/Projects'));
const UnplannedLosses = lazy(() => import('./components/UnplannedLosses/UnplannedLosses'));

export default function UnplannedLossesRoutes() {

    return (
        <>
            <Route path="/projects/:projectId/unplanned-losses" element={<ProjectProvider><UnplannedLosses /></ProjectProvider>} />
            <Route path="/" element={<ProjectProvider><Projects /></ProjectProvider>} />
        </>
    )
}