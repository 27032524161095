import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import {AuthProvider} from "./contexts/AuthContext";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
      queries: {
          retry: false,
          staleTime: 5 * 1000,
          refetchOnWindowFocus: false,
          refetchOnMount: true,
          refetchOnReconnect: false,
      },
  },
});
root.render(
  <QueryClientProvider client={queryClient}>
  <BrowserRouter>
    <AuthProvider>
       <App />
    </AuthProvider>
  </BrowserRouter>
  </QueryClientProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
