import React, { createContext, useState, useEffect } from 'react';
import UserApi from '../api/user';
import { getProject } from "../api/project";

const ProjectContext = createContext<{ id: number | null, name: string, description: string }>({ id: null, name: "", description: "" });

const ProjectProvider = ({ children }: any) => {
    const [project, setProject] = useState<{ id: number | null, name: string, description: string }>({ id: null, name: "", description: "" });

    useEffect(() => {
        const getProjectData = async () => {
            const response = await UserApi.verifyToken();
            const project = await getProject(response.project_id);
            setProject({ id: project.id, name: project.name, description: project.description });
        }
        getProjectData();
    }, []);



    return (
        <ProjectContext.Provider value={{ ...project }}>
            {children}
        </ProjectContext.Provider>
    );
}


export { ProjectProvider };
export default ProjectContext;