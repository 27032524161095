import axios from "axios";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

const client = axios.create({
    baseURL: process.env.REACT_APP_API
});
export const get = async (uri: string) => {

    const {data: response} = await client.get(uri,
        ConfigWithToken()
    )
    return response
}
export const getToken = () => {
    return localStorage.getItem('token');
}
export const ConfigWithToken=()=>{
    return {
        headers: {
            'Authorization': `Bearer ${getToken()}`,
        },
    };
}

export default client;