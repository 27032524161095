import React, {createContext, useState, useEffect} from 'react';

import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import UserApi, {LOGIN_URL} from '../api/user';

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    let token = null;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState(false);
    const [error, setError] = useState(false);
    const location = useLocation();
    const search = location.search;
    const queryParams = new URLSearchParams(search);
    const queryToken = queryParams.get('access_token');
    var referrer = document.referrer;
    let isFetching = false;

    useEffect(() => {
        if (queryToken) {
            token = queryToken;
            localStorage.setItem('token', queryToken);
        } else {
            token = localStorage.getItem('token');
        }
        if (!token) {
            logout();
        }
        if (queryToken && referrer && referrer.replace(/\/$/, "") !== process.env.REACT_APP_VERACITY_HOST.replace(/\/$/, "")) {
            logout();

        }
        !isFetching && getUser();
    }, []);

    const logout = () => {
        token = null;
        setUser(false);
        //UserApi.logout();
        localStorage.removeItem('token');
        //window.location.replace(LOGIN_URL);
    }

    const getUser = async () => {
        try {
            const token = localStorage.getItem('token');
            
            isFetching = true;
            let userData = UserApi.verifyToken();
            userData.then(function (response) {
                setUser(response);
                searchParams.delete('access_token');
                setSearchParams(searchParams);
                isFetching = false;
                if (queryToken && response.project_id != null) {
                    navigate(`projects/${response.project_id}/unplanned-losses`)
                }
            }).catch(function (err) {

                if (err.response?.status === 401) {
                    window.location.replace(LOGIN_URL);
                } else {
                    setError(true);
                }
            })
        } catch (err) {
            console.log(err);
            isFetching = false;
            setError(true);

        }
    }

    return (
        <AuthContext.Provider value={{token, setUser, user, logout, getUser, error, setError}}>
            {children}
        </AuthContext.Provider>
    );
};

export {AuthProvider};
export default AuthContext;
