import './assets/styles/App.css';
import { Suspense } from 'react';
import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom"
import UnplannedLosses from '../src/components/UnplannedLosses/UnplannedLosses';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import Box from '@mui/material/Box';
import Header from './components/common/Header';
import PageLoading from './components/common/PageLoading';
import RequireAuth from './contexts/RequireAuth';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/dist/css/bootstrap.min.css";
import '../src/assets/styles/app.min.css'
import '../src/assets/styles/rsuite.css'
import '../src/assets/styles/Header.css'
import { Toaster } from "react-hot-toast";
import UnplannedLossesRoutes from './routes';
// import '../src/assets/styles/UnplannedLosses.css'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Vag: true;
    h1VagBold: true;
    h2VagBold: true;
    h5VagBold: true;
    h5Vag: true;
    h6VagBold: true;
    h6Vag: true;
    descriptionVagBold: true;
    body2Vag: true;
    navBarVag: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  h1Vag: React.CSSProperties;
  h1VagBold: React.CSSProperties;
}

const theme = createTheme({
  typography: {
    h1Vag: {
      fontWeight: 200,
      fontFamily: 'VAGRound',
      fontSize: '3.3125rem',
      lineHeight: '1.15em',
    },
    h1VagBold: {
      fontWeight: 900,
      fontFamily: 'VAGRound',
      fontSize: '3.3125rem',
      lineHeight: '1.15em',
    },
    h2VagBold: {
      fontWeight: 900,
      fontFamily: 'VAGRound',
      fontSize: '60px',
      lineHeight: '1.15em',
    },
    h5VagBold: {
      fontWeight: 900,
      fontFamily: 'VAGRound',
      fontSize: '3.5rem',
      lineHeight: '1.15em',
    },
    h5Vag: {
      fontFamily: 'VAGRoundedLight',
      color: 'white',
      fontWeight: 900,
      fontSize: '24px'
    },
    h6VagBold: {
      fontFamily: 'VAGRound',
      fontWeight: 400,
      fontSize: "1.75em"
    },
    navBarVag: {
      fontFamily: 'VAGRound',
      fontWeight: 400,
      fontSize: "1.95em"
    },
    h6Vag: {
      fontFamily: 'VAGRoundedLight',
      color: 'white',
      fontWeight: 900,
      fontSize: '24px'
    },
    descriptionVagBold: {
      fontFamily: 'VAGRound',
      fontWeight: 300,
      fontSize: "1.5em"
    },
    body2Vag: {
      fontFamily: 'VAGRoundedLight',
      fontSize: '16px',
      fontWeight: 100,
      color: 'white',
    }
  } as ExtendedTypographyOptions,
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: 'linear-gradient(180deg, #06205D 100%, #111737 100%)'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 68,
          minHeight: 68
        }
      }
    }
  },
});

function App() {
  return (
    <div>
        <GlobalToaster />
        <RequireAuth>
          <Suspense fallback={<PageLoading />}>
            <Box sx={{ width: '100%', overflow: 'auto' }}>
              <ThemeProvider theme={theme}>
                <Routes>
                  {UnplannedLossesRoutes()}
                </Routes>
              </ThemeProvider>
            </Box>
          </Suspense>
        </RequireAuth>
    </div>
  );
}

const GlobalToaster = () => {
  return (
    <Toaster
      containerStyle={{
        top: 110,
      }}
      position="top-center"
      reverseOrder={false}
    />
  )
}

export default App;

