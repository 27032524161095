import React from 'react';
import useAuth from "../contexts/useAuth";
import PageLoading from "../components/common/PageLoading";

export default function RequireAuth({children}: any) {
    const {user, error} = useAuth();
    if (!user) {
        return <PageLoading/>
    }
    return children;

}